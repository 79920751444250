<template>
	<v-navigation-drawer v-model="advanceSearch" fixed width="530" right temporary stateless>
		<v-form ref="advanceForm">
			<div class="drawer-wrapper advance-filter">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<!-- By {{ searchTitle }} -->
					<div class="font-level-3-bold">Advance Filter</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" @click="$emit('close', true)">Cancel</v-btn>
					<v-btn tile depressed color="white--text" class="blue darken-4" @click="advanceSearchFilter"
						>Search</v-btn
					>
				</v-flex>
				<div class="drawer-content pt-5">
					<!-- <div class="form-search px-5 mb-3" v-if="searchTitle != 'Recurring'">
						<v-text-field
							placeholder="Search..."
							depressed
							hide-details
							outlined
							v-model="search"
						></v-text-field>
						<v-icon class="search-icon"> fas fa-search </v-icon>
					</div> -->
					<div class="fw-600 px-5">Filter by Below Field {{ searchTitle }}</div>
					<!-- <pre>{{ filterField }}</pre> -->

					<v-divider></v-divider>

					<div style="height: calc(100vh - 115px); overflow-y: auto">
						<div class="px-5" v-for="(filter, index) in filterField" :key="index + '_' + searchTitle">
							<template v-if="index > 1 || index == 5">
								<v-row>
									<v-col lg="3" md="3" class="d-flex align-center">
										<label :for="filter.name" class="btx-label">{{ filter.name }}</label>
									</v-col>
									<v-col lg="9" md="9" class="d-flex align-center">
										<template v-if="filter.key == 'phone' || filter.key == 'customer_phone'">
											<PhoneTemplate
												hide-details
												id="phone_number"
												:placeholder="filter.name"
												class-name="mt-0"
												v-model="serachQuery[filter.key]"
											></PhoneTemplate>
										</template>
										<template
											v-else-if="
												filter.key == 'added_at' ||
												filter.key == 'expense_date' ||
												filter.key == 'holiday_start_date' ||
												filter.key == 'holiday_end_date' ||
												filter.key == 'reminder' ||
												filter.key == 'start_date' ||
												filter.key == 'due_date' ||
												filter.key == 'start_from' ||
												filter.key == 'end_to' ||
												filter.key == 'end_date' ||
												filter.key == 'contract_start_date' ||
												filter.key == 'contract_end_date' ||
												filter.key == 'date'
											"
										>
											<DatePicker
												hideTopMargin
												hide-details
												:id="filter.key"
												clearable
												:placeholder="filter.name"
												v-model="serachQuery[filter.key]"
											></DatePicker>
										</template>
										<template v-else-if="filter.key == 'amount'">
											<v-text-field
												depressed
												hide-details
												outlined
												type="number"
												class="my-2"
												clearable
												:id="filter.key"
												:placeholder="filter.name"
												v-model="serachQuery[filter.key]"
											>
											</v-text-field>
										</template>
										<template v-else-if="filter.key == 'staff' && filter.type == 'leave'">
											<v-autocomplete
												depressed
												hide-details
												outlined
												placeholder="Select Staff"
												:items="staffList"
												item-text="display_name"
												item-value="id"
												clearable
												v-model.trim="serachQuery[filter.key]"
											>
												<template v-slot:selection="data">
													<v-chip small v-bind="data.attrs" :input-value="data.selected">
														<v-avatar left>
															<img v-if="data.item.profile_img" :src="data.item.profile_img" />
															<img
																v-else
																src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
															/>
														</v-avatar>
														{{ data.item.display_name }}
													</v-chip>
												</template>
												<template v-slot:item="data">
													<template>
														<v-list-item-avatar>
															<img v-if="data.item.profile_img" :src="data.item.profile_img" />
															<img
																v-else
																src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
															/>
														</v-list-item-avatar>
														<v-list-item-content>
															<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
														</v-list-item-content>
													</template>
												</template>
											</v-autocomplete>
										</template>
										<template v-else-if="filter.key == 'category' && filter.type == 'leave'">
											<v-autocomplete
												depressed
												hide-details
												outlined
												placeholder="Select Leave Type"
												:items="leaveTypeList"
												item-text="category"
												item-value="category"
												clearable
												v-model.trim="serachQuery[filter.key]"
											/>
											<!-- class="mt-2" -->

											<!-- <template v-slot:selection="data">
													<v-chip small v-bind="data.attrs" :input-value="data.selected">
														<v-avatar left>
															<img v-if="data.item.profile_img" :src="data.item.profile_img" />
															<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
														</v-avatar>
														{{ data.item.display_name }}
													</v-chip>
												</template>
												<template v-slot:item="data">
													<template>
														<v-list-item-avatar>
															<img v-if="data.item.profile_img" :src="data.item.profile_img" />
															<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
														</v-list-item-avatar>
														<v-list-item-content>
															<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
														</v-list-item-content>
													</template>
												</template> -->
											<!-- </v-autocomplete> -->
										</template>
										<template v-else>
											<!-- class="mt-2" -->
											<v-text-field
												depressed
												hide-details
												outlined
												clearable
												:id="filter.key"
												:placeholder="filter.name"
												v-model="serachQuery[filter.key]"
											>
											</v-text-field>
										</template>
									</v-col>
								</v-row>
							</template>
						</div>
					</div>
					<!-- <v-autocomplete
						:items="roles"
						:menu-props="{ bottom: true, offsetY: true }"
						outlined
						clearable
						hide-details
						v-model="filter.role"
						item-text="role"
						item-value="id"
						placeholder="Select Role"
						@change="filterUsers"
					>
						<template #no-data> No role found </template>
						<template #item="{ item }">
							<div class="fw-500 py-1 text-capitalize">
								{{ item.role }}
							</div>
						</template>
					</v-autocomplete> -->
				</div>
			</div>
		</v-form>
	</v-navigation-drawer>
</template>
<script>
import { QUERY } from "@/core/services/store/request.module";
import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_STATUS,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_SELECTED_ROWS,
} from "@/core/services/store/listing.module";
import PhoneTemplate from "@/view/components/Phone";
import DatePicker from "@/view/components/DatePicker";
import { SET_SEARCH_MODEL } from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";

export default {
	name: "AdvanceFilter",
	props: {
		advanceSearch: {
			type: Boolean,
			default: false,
		},
		searchEndpoint: {
			type: String,
		},
		searchTitle: {
			type: String,
		},
		btxQuery: {
			type: Object,
			default() {
				return {};
			},
		},
		staffList: {
			type: Array,
			default() {
				return [];
			},
		},
		leaveTypeList: {
			type: Array,
			default() {
				return [];
			},
		},
		filters: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			search: null,
			filterField: [],
			serachQuery: new Object(),
			pageLoading: false,
		};
	},
	watch: {
		listingFilter: {
			immediate: true,
			deep: true,
			handler(payload) {
				this.filterField = payload;
			},
		},
	},
	methods: {
		advanceSearchFilter() {
			this.$router
				.replace({
					name: this.$route.name,
					query: { ...this.serachQuery, search: this.search, status: "all" },
				})
				.then(() => {
					this.$store
						.dispatch(QUERY, {
							url: this.searchEndpoint,
							data: {
								...this.$route.query,
								status: "all",
							},
						})
						.then((data) => {
							this.$store.commit(SET_TBODY, data.tbody);
							this.$store.commit(SET_CURRENT_PAGE, data.current_page);
							this.$store.commit(SET_TOTAL_PAGE, data.total_page);
							this.$store.commit(SET_SHOWING_STRING, data.showing_string);
							this.$store.commit(SET_STATUS, data.status);
							this.$store.commit(SET_SELECTED_ROWS, []);

							if (this.search) {
								this.$store.dispatch(SET_SEARCH_MODEL, { ...this.serachQuery, search: this.search });
							}

							this.$emit("addvanceFilterData", { ...this.serachQuery, search: this.search });

							this.$emit("close", true);
						})
						.catch((error) => {
							console.log({
								error,
							});
						});
				});
		},
		/* getTheadFilter() {
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: this.searchEndpoint,
					data: {
						status: "all",
					},
				})
				.then((data) => {
					this.filterField = data.filter;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */
		getSearchQuery() {
			const parmQuery = this.$route.query;
			for (let row in parmQuery) {
				this.serachQuery[row] = parmQuery[row];
			}
		},
	},
	components: {
		PhoneTemplate,
		DatePicker,
	},
	computed: {
		...mapGetters(["listingFilter"]),
	},
	mounted() {
		// this.getTheadFilter();
		this.getSearchQuery();
	},
};
</script>
